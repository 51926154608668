<template>
  <v-container>
    <ErrorHandling class="mx-10 my-2" v-if="error" :error="error" />
    <v-row class="mb-4" justify="center">
      <v-col cols="12" xxl="8" xl="8" lg="8" md="8" sm="8">
        <v-card>
          <v-card-item>
            <v-card-title>Detail Pesanan</v-card-title>
          </v-card-item>

          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" xxl="6" xl="6" lg="6" md="6" sm="6">
                <v-img @contextmenu.prevent :src="cart[0].products[0].product_image"
                  :lazy-src="cart[0].products[0].product_image" max-width="300" height="150"></v-img>
              </v-col>
              <v-col cols="12" xxl="6" xl="6" lg="6" md="6" sm="6">
                <v-card-title class="text-h5 mt-2 text-wrap">
                  {{ cart[0].products[0].product_name }}
                </v-card-title>

                <v-card-subtitle class="ma-2"><v-icon class="d-inline" icon="mdi-map-marker"></v-icon>
                  {{
                    cart[0].products[0].product_location.productlocation_name
                  }}
                </v-card-subtitle>

                <v-card-subtitle class="ma-2"><v-avatar>
                    <v-img :src="cart[0].products[0].store_image" alt="John"></v-img>
                  </v-avatar>
                  {{ cart[0].products[0].store_name }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card-text>

          <v-container>
            <v-row>
              <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                Jenis Tiket
              </v-col>
              <v-col class="d-flex justify-end"> Sub Total </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <template v-for="item in cart[0].products[0].product_types" :key="item.producttype_id">
              <v-row class="mt-0">
                <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                  {{ cart[0].products[0].product_name }} -
                  {{ item.producttype_name }} (x{{ item.qty }})
                </v-col>
                <v-col class="d-flex justify-end text-end">
                  <div>
                    <div
                      :class="{ 'text-decoration-line-through': responseVoucher?.producttype_id == item.producttype_id }">
                      {{ numberToRupiah(item.producttype_price * item.qty) }}
                    </div>

                    <div v-if="responseVoucher?.producttype_id == item.producttype_id">
                      {{ numberToRupiah((item.producttype_price * item.qty) - responseVoucher?.amount_discount) }}
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </template>

            <v-row class="mt-0">
              <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                Total Belanja
              </v-col>
              <v-col class="d-flex justify-end">
                {{ numberToRupiah(totalOrder) }}
              </v-col>
            </v-row>

            <v-row>
              <v-col> Biaya </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                Biaya Platform
                <v-tooltip content-class="bg-grey">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" icon="mdi-information-outline">
                    </v-icon>
                  </template>

                  Jumlah total akan dibulatkan ke nilai terdekat dalam mata uang
                  yang berlaku.
                </v-tooltip>
              </v-col>

              <v-col class="d-flex justify-end">
                {{ numberToRupiah(serviceFee) }}
              </v-col>
            </v-row>

            <template v-if="
              selectPaymentMethod && this.getPaymentFee() !== 0 && !isFree
            ">
              <v-row class="mt-0">
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                  Biaya Transaksi
                </v-col>
                <v-col class="d-flex justify-end">
                  {{ numberToRupiah(this.getPaymentFee()) }}
                </v-col>
              </v-row>
            </template>

            <v-row v-if="voucherCode && discountVoucher !== 0">
              <v-col> Potongan </v-col>
            </v-row>

            <v-row v-if="voucherCode && discountVoucher !== 0" class="mt-0">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <template v-if="voucherCode && discountVoucher !== 0">
              <v-row class="mt-0">
                <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                  Diskon Voucher
                </v-col>

                <v-col class="d-flex justify-end">
                  {{ `${numberToRupiah(discountVoucher)}` }}
                </v-col>
              </v-row>
            </template>

            <v-row class="mt-0">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9" class="font-weight-bold">
                Total Pembayaran
              </v-col>
              <v-col class="d-flex justify-end font-weight-bold">
                {{
                  numberToRupiah(
                    this.totalOrder +
                    this.serviceFee +
                    (selectPaymentMethod && !isFree
                      ? this.getPaymentFee()
                      : 0)
                  )
                }}
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
        <v-card>
          <v-card-item v-if="!isFree">
            <v-card-title>Metode Pembayaran</v-card-title>
          </v-card-item>

          <v-card-text v-if="!isFree">
            <v-list>
              <template v-for="(item, i) in Object.keys(this.paymentMethod).length" :key="i">
                <v-expansion-panels class="px-2">
                  <v-expansion-panel v-if="Object.values(paymentMethod)[item - 1].length" class="my-2 pa-0">
                    <v-expansion-panel-title>
                      <span class="text-primary font-weight-bold">
                        {{ Object.keys(paymentMethod)[item - 1] }}
                      </span>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="ma-0 pa-0">
                      <v-list-item v-for="item in Object.values(paymentMethod)[item - 1]" :key="item.paymentMethod"
                        :title="item.paymentName" :value="item" @click="select(item)"
                        :active="selectPaymentMethod === item" rounded exact>
                        <template v-slot:prepend>
                          <v-img @contextmenu.prevent width="50px" :src="item.paymentImage" />
                        </template>
                      </v-list-item>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-list>
          </v-card-text>

          <v-card>
            <v-card-item>
              <v-card-title>Kode Voucher</v-card-title>
            </v-card-item>

            <v-card-text class="d-flex align-center">
              <v-text-field v-model="voucherCode" variant="outlined" class="mr-2" density="compact" hide-details
                color="primary" :readonly="discountVoucher">
              </v-text-field>

              <v-btn @click="checkVoucher" :disabled="discountVoucher" color="primary">Pasang</v-btn>
            </v-card-text>
          </v-card>

          <v-switch v-model="sendTexter" class="pa-2" color="primary" label="Kirim tiket ke Whatsapp"
            hide-details></v-switch>

          <v-checkbox v-model="checkbox">
            <template v-slot:label>
              <div class="text-subtitle-2">
                Saya setuju dengan
                <span @click="dialogSyarat = true" class="text-capitalize px-0 text-primary">Syarat & Ketentuan</span>
                yang berlaku di e-tix.com
              </div>
            </template>
          </v-checkbox>

          <div class="pa-2">
            <v-btn v-if="!showButtonCheck" :loading="isProcessing"
              :disabled="(!isFree && !selectPaymentMethod) || !checkbox || isProcessing" @click="toOrder()" block
              rounded="lg" variant="flat" color="primary" height="50" class="text-capitalize">
              Bayar Tiket
            </v-btn>

            <template v-else>
              <v-btn @click="openWindow(paymentUrl)" block rounded="lg" color="grey" variant="flat" height="50"
                class="text-capitalize mb-2">
                Halaman Pembayaran
              </v-btn>
              <v-btn @click="
                checkTransactionStatus();
              successOrder();
              " block rounded="lg" color="primary" variant="flat" height="50" class="text-capitalize">
                Status Transaksi
              </v-btn>
            </template>
          </div>

          <v-dialog v-model="dialogStatus" persistent width="auto">
            <v-card>
              <v-card-text v-if="this.trasactionStatus.data.statusCode === '00'">
                Pembayaran sudah berhasil, tiket akan dikirim ke email Anda. Cek
                folder spam jika tidak ada dalam inbox.
              </v-card-text>
              <v-card-text v-else-if="this.trasactionStatus.data.statusCode === '01'">
                Pembayaran sedang dalam proses
              </v-card-text>
              <v-card-text v-else-if="this.trasactionStatus.data.statusCode === '02'">
                Pembayaran sudah expired
              </v-card-text>
              <v-card-text v-else> Service sedang bermasalah </v-card-text>
              <v-card-actions>
                <v-btn color="primary" block @click="
                  dialogStatus = false;
                successOrder();
                ">OKE</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogTransaction" persistent width="auto">
            <v-card>
              <v-card-title class="text-h5"> Produk tertolak </v-card-title>
              <v-card-text>
                <v-list disabled>
                  <v-list-item v-for="(item, i) in rejectedOrder" :key="i">
                    <v-list-item-title>{{
                      item.product_name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green-darken-1" variant="text" @click="
                  dialogTransaction = false;
                this.$router.go('/');
                ">
                  Batal beli
                </v-btn>
                <v-btn color="green-darken-1" variant="text" @click="
                  dialogTransaction = false;
                buyProcess();
                ">
                  Lanjut beli
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <syarat-ketentuan v-model:dialog="dialogSyarat"></syarat-ketentuan>

  <v-snackbar v-model="snackbar.show" rounded="pill" location="top" color="white" width="auto" min-width="auto"
    :timeout="2000">
    <div :class="snackbar.error ? 'text-error' : 'text-primary'" class="font-weight-bold">
      <v-icon :icon="snackbar.error ? 'mdi-close-circle' : 'mdi-check-circle'"
        :color="snackbar.error ? 'error' : 'primary'"></v-icon>
      {{ snackbar.text }}
    </div>
  </v-snackbar>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import ErrorHandling from "@/components/Error.vue";
import SyaratKetentuan from "../tnc/SyaratKetentuan.vue";
import { numberToRupiah } from "@/utils/numberFormatter";
import moment from "moment";

export default {
  name: "GeneralBuy",

  components: {
    ErrorHandling,
    SyaratKetentuan,
  },

  data() {
    return {
      dialogSyarat: false,
      paymentMethod: [],
      selectPaymentMethod: null,
      isProcessing: false,
      dialog: false,
      checkbox: false,
      sendTexter: false,
      dialogTransaction: false,
      rejectedOrder: null,
      paymentTransaction: null,
      showButtonCheck: false,
      transactionId: null,
      transactionState: false,
      trasactionStatus: null,
      dialogStatus: null,
      default_image: null,
      error: "",
      paymentUrl: "",
      voucherCode: null,
      responseVoucher: null,
      discountVoucher: 0,
      snackbar: {
        show: false,
        text: null,
      },
    };
  },

  created() {
    this.getPaymentMethod();
  },

  computed: {
    ...mapGetters(["cart", "orders"]),

    computedPaymentMethod() {
      const payment = this.paymentMethod;
      for (let index = 0; index < Object.keys(payment).length; index++) {
        const element = Object.keys(payment)[index - 1];
        return element;
      }
      return 0;
    },

    totalOrder() {
      return this.cart[0].products[0].product_types.reduce(
        (a, b) => a + b.qty * b.producttype_price,
        0
      ) - (this.voucherCode && this.discountVoucher ? this.discountVoucher : 0)
    },

    serviceFee() {
      return Math.round(this.totalOrder * (3 / 100));
    },

    totalPayment() {
      return this.totalOrder + this.serviceFee + this.getPaymentFee();
    },

    mappingOrder() {
      const userCookies = Cookies.get("user_data");
      const data = JSON.parse(userCookies);
      const purchase = {
        user_id: data.user_id,
        user_email: data.email,
        orders: this.refactorCart,
        payment_method: this.isFree ? "FR" : this.selectPaymentMethod?.paymentMethod,
        amount: !this.isFree ? this.totalOrder + this.serviceFee : 0,
        voucher_code:
          this.voucherCode && this.discountVoucher ? this.voucherCode : undefined,
        send_to_texter: this.sendTexter
      };
      return purchase;
    },

    refactorCart() {
      return this.cart.map((cart) => {
        cart.products.map((product) => {
          product.product_types.map((type) => {
            type.product_id = parseInt(type.product_id);
            type.producttype_id = parseInt(type.producttype_id);
            return type;
          });
          return product;
        });
        return cart;
      });
    },

    isFree() {
      return this.totalOrder <= 0;
    },
  },
  methods: {
    numberToRupiah(number) {
      return numberToRupiah(number);
    },

    getPaymentFee() {
      if (this.selectPaymentMethod) {
        let paymentFee = this.selectPaymentMethod.totalFee;
        if (paymentFee !== "0") {
          return parseInt(paymentFee);
        } else {
          return 0;
        }
      }
    },

    select(payment) {
      this.selectPaymentMethod = payment;
    },

    async getPaymentMethod() {
      await this.axios
        .post(
          "payment/get-method",
          {
            payment_amount: this.totalOrder + this.serviceFee,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.paymentMethod = response.data.response.paymentFee;
        })
        .catch((error) => {
          if (error.response.data.error) {
            this.error = error.response.data.message;
          }
        });
    },

    async toOrder() {
      this.isProcessing = true;

      this.mappingOrder.utc = moment().utcOffset() / 60;
      await this.axios
        .post("order/product", this.mappingOrder, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (!response.data.error) {
            this.transactionId = response.data.response.transaction_id;
            this.paymentTransaction =
              response.data.response.payment_transaction;
            if (this.isFree) {
              let callback = process.env.VUE_APP_CALLBACK_DUITKU_MAILER

              if (this.sendTexter) {
                callback = process.env.VUE_APP_CALLBACK_MAILER_TEXTER
              }

              this.axios.post(callback, {
                merchantOrderId: this.transactionId,
                resultCode: "00"
              })
              this.trasactionStatus = {
                data: {
                  statusCode: "00"
                }
              }
              this.dialogStatus = true;
            }
            if (this.paymentTransaction) {
              this.showButtonCheck = true;
              this.paymentUrl =
                response.data.response.payment_transaction.pg_response.paymentUrl;
              this.openWindow(this.paymentUrl);
            }
          }
        })
        .catch((error) => {
          this.error = error.response.data.message;
        })
        .finally(() => (this.isProcessing = false));
    },

    openWindow(url) {
      const windowFeature =
        "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=700";
      window.open(url, "targetWindow", windowFeature);
    },

    async checkTransactionStatus() {
      await this.axios
        .post(
          "payment/transaction/check",
          {
            merchantCode: "DS14908",
            merchantOrderId: this.transactionId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.trasactionStatus = response;
          this.dialogStatus = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    successOrder() {
      if (
        this.trasactionStatus.data.statusCode === "00" ||
        this.trasactionStatus.data.statusCode === "02"
      ) {
        this.$router.go("/");
      }
    },

    buyProcess() {
      window.open(
        this.paymentTransaction.pg_response.paymentUrl,
        "_blank",
        "noreferrer"
      );
      setTimeout(() => {
        this.showButtonCheck = true;
      }, 2000);
    },

    checkVoucher() {
      const producttypeIds = this.cart[0].products[0].product_types.map(
        (item) => {
          return {
            producttype_id: parseInt(item.producttype_id),
            qty: item.qty
          }
        }
      );
      this.axios
        .post("voucher/check", {
          voucher_code: this.voucherCode,
          producttype_list: producttypeIds,
        })
        .then((response) => {
          this.showSnackbar("Berhasil memasang kupon/voucher");
          const result = response.data.response;
          this.responseVoucher = result;
          const amountDiscount = result.amount_discount

          this.cart.map((cart) => {
            cart.products.map((product) => {
              product.product_types.forEach((type) => {
                if (
                  type.producttype_id == this.responseVoucher.producttype_id
                ) {
                  this.discountVoucher = amountDiscount
                }
              });
            });
          });

          this.getPaymentMethod()
          this.selectPaymentMethod = null
        })
        .catch((error) => {
          this.showSnackbar("Gagal memasang kupon/voucher", true);
          console.log(error);
        });
    },

    showSnackbar(text, error = false) {
      this.snackbar.text = text;
      this.snackbar.show = true;
      this.snackbar.error = error;
    },
  },
};
</script>

<style scoped>
.buy-timer {
  background-color: #ec7100 !important;
  color: antiquewhite;
}
</style>
