<template>
  <v-container>
    <ErrorHandling class="mx-10 my-2" v-if="error" :error="error" />
    <v-row class="mb-4" justify="center">
      <v-col cols="12" xxl="8" xl="8" lg="8" md="8" sm="8">
        <v-card class="my-3">
          <v-card-item>
            <v-card-title>Detail Pesanan</v-card-title>
          </v-card-item>

          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" xxl="6" xl="6" lg="6" md="6" sm="6">
                <v-img @contextmenu.prevent :src="cart[0].products[0].product_image"
                  :lazy-src="cart[0].products[0].product_image" max-width="300" height="150"></v-img>
              </v-col>
              <v-col cols="12" xxl="6" xl="6" lg="6" md="6" sm="6">
                <v-card-title class="text-h5 mt-2 text-wrap">
                  {{ cart[0].products[0].product_name }}
                </v-card-title>

                <v-card-subtitle class="ma-2"><v-icon class="d-inline" icon="mdi-map-marker"></v-icon>
                  {{
                    cart[0].products[0].product_location.productlocation_name
                  }}
                </v-card-subtitle>

                <v-card-subtitle class="ma-2"><v-avatar>
                    <v-img :src="cart[0].products[0].store_image" alt="John"></v-img>
                  </v-avatar>
                  {{ cart[0].products[0].store_name }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card-text>

          <v-window v-model="step" :touch="false">
            <v-window-item :value="1">
              <v-container>
                <v-card variant="flat" class="my-3">
                  <v-card-item>
                    <v-card-title>Isi Data Pelanggan</v-card-title>
                  </v-card-item>

                  <!-- Contact Information -->
                  <v-card class="ma-3">
                    <v-row no-gutters @click="expand.contact = !expand.contact" align="center" justify="center"
                      class="bg-blue-grey-lighten-5">
                      <v-col cols="auto">
                        <v-card-title class="my-1 text-information text-h6 font-weight-bold">Informasi
                          Kontak</v-card-title>
                      </v-col>

                      <v-spacer></v-spacer>

                      <v-col cols="auto" class="mx-4 bg-blue-grey-lighten-5">
                        <v-icon v-if="simpan.contact && valid.contact" color="green" icon="mdi-check-decagram"></v-icon>
                        <v-icon :icon="expand.contact
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                          "></v-icon>
                      </v-col>
                    </v-row>

                    <v-expand-transition>
                      <div v-show="expand.contact">
                        <v-card-text class="py-0">
                          <v-form ref="refContact" v-model="valid.contact">
                            <v-row class="mt-3">
                              <v-col>
                                <v-text-field v-model="input.contact.full_name" :rules="rules.full_name"
                                  @update:model-value="simpan.contact = false" :counter="charLimit.contact.full_name"
                                  :maxlength="charLimit.contact.full_name" variant="outlined" label="Nama Lengkap
                              " placeholder="Nama Lengkap
                              " dense color="primary" class="mx-2">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field v-model="input.contact.email" :rules="rules.email"
                                  @update:model-value="simpan.contact = false" :counter="charLimit.contact.email"
                                  :maxlength="charLimit.contact.email" variant="outlined" label="Email"
                                  placeholder="Email" dense type="email" color="primary" class="mx-2">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field v-model="input.contact.phone" :rules="rules.phone"
                                  @keypress="validation.input.isNumber" @update:model-value="simpan.contact = false"
                                  :counter="charLimit.contact.phone_number" :maxlength="charLimit.contact.phone_number"
                                  variant="outlined" label="Nomor Handphone" prefix="+62" placeholder="Nomor Handphone"
                                  dense color="primary" class="mx-2">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-textarea v-model="input.contact.address" :rules="rules.address"
                                  @update:model-value="simpan.contact = false" :counter="charLimit.contact.address"
                                  :maxlength="charLimit.contact.address" variant="outlined" label="Alamat Lengkap"
                                  placeholder="Alamat Lengkap" dense color="primary" class="mx-2">
                                </v-textarea>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-autocomplete v-model="input.contact.province" :rules="rules.province"
                                  @update:model-value="simpan.contact = false" :items="province" variant="outlined"
                                  item-title="locationprovince" item-value="locationprovince_id" validate-on="blur"
                                  label="Provinsi" placeholder="Pilih Provinsi domisili Anda" dense color="primary"
                                  class="mx-2">
                                </v-autocomplete>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-autocomplete v-model="input.contact.city" :disabled="!input.contact.province"
                                  :rules="rules.city" @update:model-value="simpan.contact = false"
                                  :items="computedCities" variant="outlined" item-title="locationcity_name"
                                  item-value="locationcity_id" validate-on="blur" label="Kota/Kabupaten"
                                  placeholder="Pilih Kota/Kabupaten domisili Anda" dense color="primary" class="mx-2">
                                </v-autocomplete>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-autocomplete v-model="input.contact.region" :rules="rules.region"
                                  @update:model-value="simpan.contact = false" :items="countries" variant="outlined"
                                  item-title="locationcountry_name" item-value="locationcountry_id" validate-on="blur"
                                  label="Warga Negara" placeholder="Pilih Warga Negara Anda" dense color="primary"
                                  class="mx-2">
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>

                        <v-card-item class="mx-2 mb-2">
                          <v-btn color="primary" variant="flat" @click="
                            addContactInformation()
                              ? (simpan.contact = true)
                              : (simpan.contact = false)
                            " class="text-none text-caption">Simpan</v-btn>
                        </v-card-item>
                      </div>
                    </v-expand-transition>
                  </v-card>

                  <!-- Participants Detail -->
                  <v-card class="ma-3">
                    <v-row no-gutters @click="expand.participants = !expand.participants" align="center"
                      justify="center" class="bg-blue-grey-lighten-5">
                      <v-col cols="auto">
                        <v-card-title class="my-1 text-information text-h6 font-weight-bold">Detail
                          Peserta</v-card-title>
                      </v-col>

                      <v-spacer></v-spacer>

                      <v-col cols="auto" class="mx-4 bg-blue-grey-lighten-5">
                        <v-icon v-if="simpan.participants && valid.participants" color="green"
                          icon="mdi-check-decagram"></v-icon>
                        <v-icon :icon="expand.participants
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                          "></v-icon>
                      </v-col>
                    </v-row>

                    <v-expand-transition>
                      <div v-show="expand.participants">
                        <v-card-text class="py-0">
                          <v-form ref="refParticipants" v-model="valid.participants">
                            <!-- nama depan dan belakang -->
                            <v-row class="mt-3">
                              <v-col>
                                <v-text-field v-model="input.participants.first_name" :rules="rules.first_name"
                                  @update:model-value="
                                    simpan.participants = false
                                    " :counter="charLimit.participants.first_name"
                                  :maxlength="charLimit.participants.first_name" variant="outlined" label="Nama Depan"
                                  placeholder="Nama Depan" dense color="primary" class="mx-2">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field v-model="input.participants.last_name" :rules="rules.last_name"
                                  @update:model-value="
                                    simpan.participants = false
                                    " :counter="charLimit.participants.last_name"
                                  :maxlength="charLimit.participants.last_name" variant="outlined" label="Nama Belakang"
                                  placeholder="Nama Belakang" dense color="primary" class="mx-2">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <!-- kartu identitas dan paspor -->
                            <v-row>
                              <v-col>
                                <v-text-field v-model="input.participants.identity_card" :rules="rules.identity_card"
                                  @update:model-value="
                                    simpan.participants = false
                                    " @keypress="validation.input.isNumber" :counter="charLimit.participants.identity_card
                                      " :maxlength="charLimit.participants.identity_card
                                        " variant="outlined" label="No. Kartu Identitas/No. Paspor"
                                  placeholder="No. Kartu Identitas/No. Paspor" dense color="primary" class="mx-2">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <!-- tanggal lahir dan golongan darah -->
                            <v-row class="mt-3">
                              <v-col>
                                <v-text-field v-model="input.participants.date_of_birth" :rules="rules.date_of_birth"
                                  @update:model-value="
                                    simpan.participants = false
                                    " type="date" :max="maxDate" variant="outlined" label="Tanggal Lahir"
                                  placeholder="Tanggal Lahir" dense color="primary" class="mx-2" messages=" ">
                                  <template #message>
                                    <div v-if="input.participants.date_of_birth">
                                      {{
                                        `Umur ${getAge(
                                          input.participants.date_of_birth
                                        ).years
                                        } tahun ${getAge(
                                          input.participants.date_of_birth
                                        ).months
                                        } bulan dari tanggal acara`
                                      }}
                                    </div>
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-autocomplete v-model="input.participants.blood_type" :rules="rules.blood_type"
                                  @update:model-value="
                                    simpan.participants = false
                                    " variant="outlined" :items="bloods" item-title="bloodtype_name"
                                  item-value="bloodtype_name" validate-on="blur" label="Golongan Darah"
                                  placeholder="Pilih golongan darah" dense color="primary" class="mx-2">
                                </v-autocomplete>
                              </v-col>
                            </v-row>

                            <!-- jenis kelamin -->
                            <v-row>
                              <v-col>
                                <v-radio-group inline color="primary" class="ma-0 text-black font-weight-bold"
                                  label="Jenis Kelamin" v-model="input.participants.gender" :rules="rules.gender"
                                  @update:model-value="
                                    simpan.participants = false
                                    ">
                                  <v-radio v-for="gender in genders" :key="gender.gender_id"
                                    class="text-black mr-12 pr-12" :label="gender.gender_name"
                                    :value="gender.gender_id"></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>

                            <!-- nama punggung -->
                            <v-row>
                              <v-col>
                                <v-text-field v-model="input.participants.bib_name" :rules="rules.bib_name"
                                  @update:model-value="
                                    simpan.participants = false
                                    " :counter="charLimit.participants.bib_name"
                                  :maxlength="charLimit.participants.bib_name" variant="outlined" label="Nama BIB"
                                  placeholder="Nama BIB" dense color="primary" class="mx-2">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <!-- ukuran jersey -->
                            <!-- <v-row class="mt-3">
                              <v-col>
                                <v-radio-group inline color="primary" class="ma-0 text-black font-weight-bold"
                                  label="Ukuran Kaos" v-model="input.participants.size_shirt" :rules="rules.size_shirt"
                                  @update:model-value="
                                    simpan.participants = false
                                    ">
                                  <template v-for="size in size_shirt" :key="size.sizetype_id">
                                    <v-radio class="text-black mr-10" :label="size.sizetype_name"
                                      :value="size.sizetype_id"></v-radio>
                                  </template>
                                </v-radio-group>
                              </v-col>
                            </v-row> -->

                            <!-- medical checkup -->
                            <v-row>
                              <v-col>
                                <v-textarea v-model="input.participants.medical_condition" @update:model-value="
                                  simpan.participants = false
                                  " :counter="charLimit.participants.medical_condition
                                    " :maxlength="charLimit.participants.medical_condition
                                      " variant="outlined" label="Kondisi Kesehatan"
                                  placeholder="Tulis kondisi medis/sakit yang Anda alami" dense color="primary"
                                  class="mx-2">
                                </v-textarea>
                              </v-col>
                            </v-row>

                            <!-- checkup -->
                            <v-row>
                              <v-col>
                                <v-radio-group v-model="input.participants.is_checkup" :rules="rules.is_checkup"
                                  @update:model-value="
                                    simpan.participants = false
                                    " inline color="primary">
                                  <template v-slot:label>
                                    <p id="wrappedCheckup" class="text-black font-weight-bold">
                                      Apakah Anda sudah melakukan medical check
                                      up dalam 3 bulan terakhir?
                                    </p>
                                  </template>
                                  <v-radio class="text-black mr-12 pr-12" label="Ya" :value="true"></v-radio>
                                  <v-radio class="text-black mr-12 pr-12" label="Tidak" :value="false"></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>

                        <v-card-item class="mx-2 mb-2">
                          <v-btn color="primary" variant="flat" @click="
                            addParticipantsInformation()
                              ? (simpan.participants = true)
                              : (simpan.participants = false)
                            " class="text-none text-caption">
                            Simpan</v-btn>
                        </v-card-item>
                      </div>
                    </v-expand-transition>
                  </v-card>

                  <!-- Emergency Contact -->
                  <v-card class="ma-3">
                    <v-row no-gutters @click="expand.emergency = !expand.emergency" align="center" justify="center"
                      class="bg-blue-grey-lighten-5">
                      <v-col cols="auto">
                        <v-card-title class="my-1 text-information text-h6 font-weight-bold">Kontak
                          Darurat</v-card-title>
                      </v-col>

                      <v-spacer></v-spacer>

                      <v-col cols="auto" class="mx-4 bg-blue-grey-lighten-5">
                        <v-icon v-if="simpan.emergency && valid.emergency" color="green"
                          icon="mdi-check-decagram"></v-icon>
                        <v-icon :icon="expand.emergency
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                          "></v-icon>
                      </v-col>
                    </v-row>

                    <v-expand-transition>
                      <div v-show="expand.emergency">
                        <v-card-text class="py-0">
                          <p class="text-subtitle-2 font-weight-bold">
                            Isi informasi kontak darurat yang bisa dihubungi
                          </p>
                          <v-form ref="refEmergency" v-model="valid.emergency">
                            <v-row class="mt-3">
                              <v-col>
                                <v-text-field v-model="input.emergency.full_name" :rules="rules.full_name"
                                  @update:model-value="simpan.emergency = false"
                                  :counter="charLimit.emergency.full_name" :maxlength="charLimit.emergency.full_name"
                                  variant="outlined" label="Nama Lengkap" placeholder="Nama Lengkap" dense
                                  color="primary" class="mx-2">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field v-model="input.emergency.phone" :rules="rules.phone"
                                  @keypress="validation.input.isNumber" @update:model-value="simpan.emergency = false"
                                  :counter="charLimit.emergency.phone_number"
                                  :maxlength="charLimit.emergency.phone_number" variant="outlined"
                                  label="Nomor Handphone" placeholder="Nomor Handphone" prefix="+62" dense
                                  color="primary" class="mx-2">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-autocomplete v-model="input.emergency.relation" :rules="rules.relation"
                                  @update:model-value="simpan.emergency = false" :items="relations" variant="outlined"
                                  item-title="relation_name" item-value="value" validate-on="blur"
                                  label="Hubungan/Relasi" placeholder="Hubungan/Relasi" dense color="primary"
                                  class="mx-2">
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>

                        <v-card-item class="mx-2 mb-2">
                          <v-btn color="primary" variant="flat" @click="
                            addEmergencyInformation()
                              ? (simpan.emergency = true)
                              : (simpan.emergency = false)
                            " class="text-none text-caption">
                            Simpan</v-btn>
                        </v-card-item>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </v-card>
              </v-container>
            </v-window-item>

            <v-window-item :value="2">
              <v-container>
                <v-row>
                  <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                    Jenis Tiket
                  </v-col>
                  <v-col class="d-flex justify-end"> Sub Total </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <template v-for="item in cart[0].products[0].product_types" :key="item.producttype_id">
                  <v-row class="mt-0">
                    <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                      {{ cart[0].products[0].product_name }} -
                      {{ item.producttype_name }} (x{{ item.qty }})
                    </v-col>
                    <v-col class="d-flex justify-end text-end">
                      <div>
                        <div
                          :class="{ 'text-decoration-line-through': responseVoucher?.producttype_id == item.producttype_id }">
                          {{ numberToRupiah(item.producttype_price * item.qty) }}
                        </div>

                        <div v-if="responseVoucher?.producttype_id == item.producttype_id">
                          {{ numberToRupiah((item.producttype_price * item.qty) - responseVoucher?.amount_discount) }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </template>

                <v-row class="mt-0">
                  <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                    Total Belanja
                  </v-col>
                  <v-col class="d-flex justify-end">
                    {{ numberToRupiah(totalOrder) }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col> Biaya </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                    Biaya Platform
                    <v-tooltip content-class="bg-grey">
                      <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" icon="mdi-information-outline">
                        </v-icon>
                      </template>

                      Jumlah total akan dibulatkan ke nilai terdekat dalam mata
                      uang yang berlaku.
                    </v-tooltip>
                  </v-col>

                  <v-col class="d-flex justify-end">
                    {{ numberToRupiah(serviceFee) }}
                  </v-col>
                </v-row>

                <template v-if="
                  selectPaymentMethod && this.getPaymentFee() !== 0 && !isFree
                ">
                  <v-row class="mt-0">
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                      Biaya Transaksi
                    </v-col>
                    <v-col class="d-flex justify-end">
                      {{ numberToRupiah(this.getPaymentFee()) }}
                    </v-col>
                  </v-row>
                </template>

                <v-row v-if="voucherCode && discountVoucher !== 0">
                  <v-col> Potongan </v-col>
                </v-row>

                <v-row v-if="voucherCode && discountVoucher !== 0" class="mt-0">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <template v-if="voucherCode && discountVoucher !== 0">
                  <v-row class="mt-0">
                    <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9">
                      Diskon Voucher
                    </v-col>

                    <v-col class="d-flex justify-end">
                      {{ `${numberToRupiah(discountVoucher)}` }}
                    </v-col>
                  </v-row>
                </template>

                <v-row class="mt-0">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col cols="6" xxl="9" xl="9" lg="9" md="9" sm="9" class="font-weight-bold">
                    Total Pembayaran
                  </v-col>
                  <v-col class="d-flex justify-end font-weight-bold">
                    {{
                      numberToRupiah(
                        this.totalOrder +
                        this.serviceFee +
                        (selectPaymentMethod && !isFree
                          ? this.getPaymentFee()
                          : 0)
                      )
                    }}
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>

      <v-col cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
        <v-window v-model="step" :touch="false">
          <v-window-item :value="1">
            <v-card>
              <v-card-item>
                <v-card-title>Ringkasan Data Tiket</v-card-title>
              </v-card-item>

              <v-card-text>
                <v-card variant="outlined" color="primary">
                  <v-card-text>
                    <p class="text-subtitle-2 text-black font-weight-bold">
                      {{
                        cart[0].products[0].product_types[0].producttype_name
                      }}
                    </p>

                    <v-divider class="mb-2 border-dashed" :thickness="2"></v-divider>

                    <p class="text-subtitle-2 text-black font-weight-bold">
                      Informasi Kontak
                    </p>

                    <p class="text-subtitle-2 text-black font-weight-regular">
                      {{
                        simpan.contact && valid.contact
                          ? input.contact.full_name
                          : "-"
                      }}
                    </p>

                    <p class="text-subtitle-2 text-black font-weight-regular">
                      {{
                        simpan.contact && valid.contact
                          ? input.contact.email
                          : "-"
                      }}
                    </p>

                    <p class="text-subtitle-2 text-black font-weight-bold">
                      Detail Peserta
                    </p>

                    <p class="text-subtitle-2 text-black font-weight-regular">
                      {{
                        simpan.participants && valid.participants
                          ? `${input.participants.first_name} ${input.participants.last_name}`
                          : "-"
                      }}
                    </p>

                    <p class="text-subtitle-2 text-black font-weight-regular">
                      {{
                        `Nama BIB: ${simpan.participants && valid.participants
                          ? input.participants.bib_name
                          : "-"
                        }`
                      }}
                    </p>

                    <p class="text-subtitle-2 text-black font-weight-bold">
                      Kontak Darurat
                    </p>

                    <p class="text-subtitle-2 text-black font-weight-regular">
                      {{
                        simpan.emergency && valid.emergency
                          ? input.emergency.full_name
                          : "-"
                      }}
                    </p>

                    <p class="text-subtitle-2 text-black font-weight-regular">
                      {{
                        simpan.emergency && valid.emergency
                          ? input.emergency.phone
                          : "-"
                      }}
                    </p>

                    <p class="text-subtitle-2 text-black font-weight-regular">
                      {{
                        simpan.emergency && valid.emergency
                          ? input.emergency.relation
                          : "-"
                      }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-card-text>

              <div class="pa-2">
                <v-btn v-if="!showButtonCheck" :loading="isProcessing" :disabled="isProcessing || !validState"
                  @click="step = 2" block rounded="lg" variant="flat" color="primary" height="50"
                  class="text-capitalize">
                  Lanjut
                </v-btn>
              </div>
            </v-card>
          </v-window-item>

          <v-window-item :value="2">
            <v-card>
              <v-card-item v-if="!isFree">
                <v-card-title>Metode Pembayaran</v-card-title>
              </v-card-item>

              <v-card-text v-if="!isFree">
                <v-list>
                  <template v-for="(item, i) in Object.keys(this.paymentMethod).length" :key="i">
                    <v-expansion-panels class="px-2">
                      <v-expansion-panel v-if="Object.values(paymentMethod)[item - 1].length" class="my-2 pa-0">
                        <v-expansion-panel-title>
                          <span class="text-primary font-weight-bold">
                            {{ Object.keys(paymentMethod)[item - 1] }}
                          </span>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="ma-0 pa-0">
                          <v-list-item v-for="item in Object.values(paymentMethod)[
                            item - 1
                          ]" :key="item.paymentMethod" :title="item.paymentName" :value="item" @click="select(item)"
                            :active="selectPaymentMethod === item" rounded exact>
                            <template v-slot:prepend>
                              <v-img @contextmenu.prevent width="50px" :src="item.paymentImage" />
                            </template>
                          </v-list-item>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template>
                </v-list>
              </v-card-text>

              <v-card>
                <v-card-item>
                  <v-card-title>Kode Voucher</v-card-title>
                </v-card-item>

                <v-card-text class="d-flex align-center">
                  <v-text-field v-model="voucherCode" variant="outlined" class="mr-2" density="compact" hide-details
                    color="primary" :readonly="discountVoucher">
                  </v-text-field>

                  <v-btn @click="checkVoucher" :disabled="discountVoucher" color="primary">Pasang</v-btn>
                </v-card-text>
              </v-card>

              <v-switch v-model="sendTexter" class="pa-2" color="primary" label="Kirim tiket ke Whatsapp"
                hide-details></v-switch>

              <v-checkbox v-model="checkbox">
                <template v-slot:label>
                  <div class="text-subtitle-2">
                    Saya setuju dengan
                    <span @click="dialogSyarat = true" class="text-capitalize px-0 text-primary">Syarat &
                      Ketentuan</span>
                    yang berlaku di e-tix.com
                  </div>
                </template>
              </v-checkbox>

              <div class="pa-2">
                <v-btn v-if="!showButtonCheck" :loading="isProcessing"
                  :disabled="(!isFree && !selectPaymentMethod) || !checkbox || isProcessing" @click="toOrder()" block
                  rounded="lg" variant="flat" color="primary" height="50" class="text-capitalize">
                  Bayar Tiket
                </v-btn>

                <template v-else>
                  <v-btn @click="openWindow(paymentUrl)" block rounded="lg" color="grey" variant="flat" height="50"
                    class="text-capitalize mb-2">
                    Halaman Pembayaran
                  </v-btn>
                  <v-btn @click="
                    checkTransactionStatus();
                  successOrder();
                  " block rounded="lg" color="primary" variant="flat" height="50" class="text-capitalize">
                    Status Transaksi
                  </v-btn>
                </template>
              </div>

              <v-dialog v-model="dialogStatus" persistent width="auto">
                <v-card>
                  <v-card-text v-if="this.trasactionStatus.data.statusCode === '00'">
                    Pembayaran sudah berhasil, tiket akan dikirim ke email Anda.
                    Cek folder spam jika tidak ada dalam inbox.
                  </v-card-text>
                  <v-card-text v-else-if="this.trasactionStatus.data.statusCode === '01'">
                    Pembayaran sedang dalam proses
                  </v-card-text>
                  <v-card-text v-else-if="this.trasactionStatus.data.statusCode === '02'">
                    Pembayaran sudah expired
                  </v-card-text>
                  <v-card-text v-else> Service sedang bermasalah </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" block @click="
                      dialogStatus = false;
                    successOrder();
                    ">OKE</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogTransaction" persistent width="auto">
                <v-card>
                  <v-card-title class="text-h5"> Produk tertolak </v-card-title>
                  <v-card-text>
                    <v-list disabled>
                      <v-list-item v-for="(item, i) in rejectedOrder" :key="i">
                        <v-list-item-title>{{
                          item.product_name
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green-darken-1" variant="text" @click="
                      dialogTransaction = false;
                    this.$router.go('/');
                    ">
                      Batal beli
                    </v-btn>
                    <v-btn color="green-darken-1" variant="text" @click="
                      dialogTransaction = false;
                    buyProcess();
                    ">
                      Lanjut beli
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>

  <syarat-ketentuan v-model:dialog="dialogSyarat"></syarat-ketentuan>

  <v-snackbar v-model="snackbar.show" rounded="pill" location="top" color="white" width="auto" min-width="auto"
    :timeout="2000">
    <div :class="snackbar.error ? 'text-error' : 'text-primary'" class="font-weight-bold">
      <v-icon :icon="snackbar.error ? 'mdi-close-circle' : 'mdi-check-circle'"
        :color="snackbar.error ? 'error' : 'primary'"></v-icon>
      {{ snackbar.text }}
    </div>
  </v-snackbar>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import ErrorHandling from "@/components/Error.vue";
import SyaratKetentuan from "../tnc/SyaratKetentuan.vue";
import { numberToRupiah } from "@/utils/numberFormatter";
import charLimit from "@/config/limitation";
import moment from "moment";
import isEmail from "validator/lib/isEmail";
import { isValidPhoneNumber } from 'libphonenumber-js'
import * as validation from "@/utils/validation";
import { utcToLocale } from "@/utils/dateFormatter";

export default {
  data() {
    return {
      dialogSyarat: false,
      paymentMethod: [],
      selectPaymentMethod: null,
      isProcessing: false,
      checkbox: false,
      sendTexter: false,
      dialogTransaction: false,
      rejectedOrder: null,
      paymentTransaction: null,
      showButtonCheck: false,
      transactionId: null,
      transactionState: false,
      trasactionStatus: null,
      dialogStatus: null,
      default_image: null,
      error: "",
      paymentUrl: "",
      rules: {
        full_name: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        email: [
          (v) => !!v || "Kamu perlu mengisi bagian ini",
          (v) => isEmail(v) || "Tolong masukkan alamat email yang benar",
        ],
        phone: [
          (v) => !!v || "Kamu perlu mengisi bagian ini",
          (v) => {
            var regExp = /[a-zA-Z]/g;
            if (!regExp.test(v)) {
              return (
                (isValidPhoneNumber("0" + v) && v.startsWith("8")) || "Tolong masukkan nomor handphone yang benar"
              );
            }
          },
          (v) =>
            v.length >= 10 ||
            "Nomor HP terdiri atas minimal 10 digit",
        ],
        address: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        province: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        city: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        region: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        first_name: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        last_name: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        bib_name: [
          (v) => !!v || "Kamu perlu mengisi bagian ini",
          (v) => v.length <= 8 || "Nama BIB terdiri atas maksimal 8 karakter",
        ],
        identity_card: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        blood_type: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        gender: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        date_of_birth: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        // size_shirt: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        is_checkup: [
          (v) => v === true || v === false || "Kamu perlu mengisi bagian ini",
        ],
        relation: [(v) => !!v || "Kamu perlu mengisi bagian ini"],
        tnc: [(v) => !!v || "Kamu harus menyetujui untuk melanjutkan"],
      },
      input: {
        contact: {
          full_name: null,
          email: null,
          phone: null,
          address: null,
          province: null,
          city: null,
          region: null,
        },
        participants: {
          first_name: null,
          last_name: null,
          bib_name: null,
          identity_card: null,
          blood_type: null,
          gender: null,
          date_of_birth: null,
          // size_shirt: null,
          medical_condition: null,
          is_checkup: null,
        },
        emergency: {
          full_name: null,
          phone: null,
          relation: null,
        },
        agreement: null,
      },
      validation: {
        quantity: validation.default.quantity,
        cart: validation.default.cart,
        input: validation.default.input,
      },
      valid: {
        contact: false,
        participants: false,
        emergency: false,
      },
      simpan: {
        contact: false,
        participants: false,
        emergency: false,
      },
      dialog: {
        status: false,
        text: null,
      },
      cities: [],
      countries: [],
      province: [],
      bloods: [
        {
          bloodtype_id: 1,
          bloodtype_name: "A",
        },
        {
          bloodtype_id: 2,
          bloodtype_name: "B",
        },
        {
          bloodtype_id: 3,
          bloodtype_name: "AB",
        },
        {
          bloodtype_id: 4,
          bloodtype_name: "O",
        },
      ],
      genders: [
        {
          gender_id: 1,
          gender_name: "Pria",
        },
        {
          gender_id: 2,
          gender_name: "Wanita",
        },
      ],
      // size_shirt: [
      //   {
      //     sizetype_id: 1,
      //     sizetype_name: "XS",
      //   },
      //   {
      //     sizetype_id: 2,
      //     sizetype_name: "S",
      //   },
      //   {
      //     sizetype_id: 3,
      //     sizetype_name: "M",
      //   },
      //   {
      //     sizetype_id: 4,
      //     sizetype_name: "L",
      //   },
      //   {
      //     sizetype_id: 5,
      //     sizetype_name: "XL",
      //   },
      //   {
      //     sizetype_id: 6,
      //     sizetype_name: "2XL",
      //   },
      // ],
      relations: [
        {
          relation_id: 1,
          relation_name: "Orang Tua",
          value: "parent",
        },
        {
          relation_id: 2,
          relation_name: "Suami / Istri",
          value: "husband/wife",
        },
        {
          relation_id: 3,
          relation_name: "Saudara",
          value: "sibling/cousin",
        },
        {
          relation_id: 4,
          relation_name: "Teman",
          value: "friend",
        },
        {
          relation_id: 5,
          relation_name: "Lainnya",
          value: "other",
        },
      ],
      expand: {
        contact: false,
        participants: false,
        emergency: false,
      },
      charLimit: charLimit.product.race,
      step: 1,
      voucherCode: null,
      responseVoucher: null,
      discountVoucher: 0,
      snackbar: {
        show: false,
        text: null,
      },
    };
  },

  components: {
    ErrorHandling,
    SyaratKetentuan,
  },

  async created() {
    this.getPaymentMethod();
    const provinceReq = await this.axios.get("product/province", {
      params: {
        page: 1,
        pageSize: 1500,
      },
    });
    this.province = provinceReq.data.response.provinces.map((province) => {
      if (province.locationprovince) {
        return {
          ...province,
          locationprovince: province.locationprovince
            .toLowerCase()
            .replace(/\b\w/g, (s) => s.toUpperCase()),
        };
      }
      return province;
    });

    const cityReq = await this.axios.get("product/city", {
      params: {
        page: 1,
        pageSize: 1500,
      },
    });
    const city = cityReq.data.response.cities.map((city) => {
      if (city.locationcity_name) {
        return {
          ...city,
          locationcity_name: city.locationcity_name
            .toLowerCase()
            .replace(/\b\w/g, (s) => s.toUpperCase()),
        };
      }
      return city;
    });
    this.cities = city;

    const countryReq = await this.axios.get("product/country", {
      params: {
        page: 1,
        pageSize: 1500,
      },
    });
    this.countries = countryReq.data.response.countries;
  },

  computed: {
    ...mapGetters(["cart", "orders"]),

    computedPaymentMethod() {
      const payment = this.paymentMethod;
      for (let index = 0; index < Object.keys(payment).length; index++) {
        const element = Object.keys(payment)[index - 1];
        return element;
      }
      return 0;
    },

    totalOrder() {
      return this.cart[0].products[0].product_types.reduce(
        (a, b) => a + b.qty * b.producttype_price,
        0
      ) - (this.voucherCode && this.discountVoucher ? this.discountVoucher : 0)
    },

    serviceFee() {
      return Math.round(this.totalOrder * (3 / 100));
    },

    totalPayment() {
      return this.totalOrder + this.serviceFee + this.getPaymentFee();
    },

    maxDate() {
      return moment().format("YYYY-MM-DD");
    },

    mappingOrder() {
      let cartOrder = this.cart;
      const userInformation = {};
      const convert =
        this.$store.state.user_race.participants.date_of_birth + "T" + "00:00";
      const bornDate = utcToLocale(convert);
      userInformation["infotype_id"] = 1;
      userInformation["orderdetailinfo_name"] =
        this.$store.state.user_race.contact.full_name;
      userInformation["orderdetailinfo_content"] =
        this.$store.state.user_race.participants.identity_card;
      userInformation["orderdetailinfo_phone"] =
        `62${this.$store.state.user_race.contact.phone}`
      userInformation["orderdetailinfo_email"] =
        this.$store.state.user_race.contact.email;
      userInformation["orderdetailinfo_alamat"] =
        this.$store.state.user_race.contact.address;
      userInformation["orderdetailinfo_provinsi"] =
        this.$store.state.user_race.contact.province;
      userInformation["orderdetailinfo_kota"] =
        this.$store.state.user_race.contact.city;
      userInformation["orderdetailinfo_warganegara"] =
        this.$store.state.user_race.contact.region;
      userInformation["nama_depan"] =
        this.$store.state.user_race.participants.first_name;
      userInformation["nama_belakang"] =
        this.$store.state.user_race.participants.last_name;
      userInformation["nama_bib"] =
        this.$store.state.user_race.participants.bib_name;
      userInformation["golongan_darah"] =
        this.$store.state.user_race.participants.blood_type;
      userInformation["jenis_kelamin"] =
        this.$store.state.user_race.participants.gender;
      userInformation["tanggal_lahir"] = bornDate;
      // userInformation["size_baju"] =
      //   this.$store.state.user_race.participants.size_shirt;
      userInformation["medical_condition"] =
        this.$store.state.user_race.participants.medical_condition;
      userInformation["is_checkup"] =
        this.$store.state.user_race.participants.is_checkup;
      userInformation["nama_darurat"] =
        this.$store.state.user_race.emergency.full_name;
      userInformation["phone_darurat"] =
        `62${this.$store.state.user_race.emergency.phone}`
      userInformation["relasi_darurat"] =
        this.$store.state.user_race.emergency.relation;
      const orderType = cartOrder[0].products[0].product_types.map((type) => {
        type.additional_info = [userInformation];
        return type;
      });
      cartOrder[0].products[0].product_types = orderType;
      const userCookies = Cookies.get("user_data");
      const data = JSON.parse(userCookies);
      const purchase = {
        user_id: data.user_id,
        user_email: data.email,
        orders: this.refactorCart(cartOrder),
        payment_method: this.isFree ? "FR" : this.selectPaymentMethod?.paymentMethod,
        amount: !this.isFree ? this.totalOrder + this.serviceFee : 0,
        utc: moment().utcOffset() / 60,
        voucher_code:
          this.voucherCode && this.discountVoucher ? this.voucherCode : undefined,
        send_to_texter: this.sendTexter,
      };
      return purchase;
    },

    isFree() {
      return this.totalOrder <= 0;
    },

    validState() {
      return (
        this.simpan.contact &&
        this.simpan.participants &&
        this.simpan.emergency &&
        this.valid.contact &&
        this.valid.participants &&
        this.valid.emergency
      );
    },

    computedCities() {
      const data = this.cities.filter(
        (city) => city.locationprovince_id === this.input.contact.province
      );

      return data;
    },
  },

  methods: {
    numberToRupiah(number) {
      return numberToRupiah(number);
    },

    getPaymentFee() {
      if (this.selectPaymentMethod) {
        let paymentFee = this.selectPaymentMethod.totalFee;
        if (paymentFee !== "0") {
          return parseInt(paymentFee);
        } else {
          return 0;
        }
      }
    },

    select(payment) {
      this.selectPaymentMethod = payment;
    },

    async getPaymentMethod() {
      await this.axios
        .post(
          "payment/get-method",
          {
            payment_amount: this.totalOrder + this.serviceFee,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.paymentMethod = response.data.response.paymentFee;
        })
        .catch((error) => {
          if (error.response.data.error) {
            this.error = error.response.data.message;
          }
        });
    },

    async addContactInformation() {
      const { valid } = await this.$refs.refContact.validate();

      this.valid.contact = valid;

      if (valid) {
        this.$store.commit("addUserRaceContactInformation", {
          full_name: this.input.contact.full_name,
          email: this.input.contact.email,
          phone: this.input.contact.phone,
          address: this.input.contact.address,
          province: this.input.contact.province,
          city: this.input.contact.city,
          region: this.input.contact.region,
        });
        this.expand.contact = false;
        return true;
      } else {
        return false;
      }
    },

    async addParticipantsInformation() {
      const { valid } = await this.$refs.refParticipants.validate();

      this.valid.participants = valid;

      if (valid) {
        this.$store.commit("addUserRaceParticipantsInformation", {
          first_name: this.input.participants.first_name,
          last_name: this.input.participants.last_name,
          bib_name: this.input.participants.bib_name,
          identity_card: this.input.participants.identity_card,
          blood_type: this.input.participants.blood_type,
          gender: this.input.participants.gender,
          date_of_birth: this.input.participants.date_of_birth,
          // size_shirt: this.input.participants.size_shirt,
          medical_condition: this.input.participants.medical_condition,
          is_checkup: this.input.participants.is_checkup,
        });
        this.expand.participants = false;
        return true;
      } else {
        return false;
      }
    },

    async addEmergencyInformation() {
      const { valid } = await this.$refs.refEmergency.validate();

      this.valid.emergency = valid;

      if (valid) {
        this.$store.commit("addUserRaceEmergencyInformation", {
          full_name: this.input.emergency.full_name,
          phone: this.input.emergency.phone,
          relation: this.input.emergency.relation,
        });
        this.expand.emergency = false;
        return true;
      } else {
        return false;
      }
    },

    getAge(dateString) {
      const product = this.cart[0].products[0];
      const eventStart = moment.utc(product.event_start).local();
      const convert = dateString + "T" + "00:00";
      const dateOfBirth = utcToLocale(convert);
      const years = eventStart.diff(dateOfBirth, "years", false);
      const months = eventStart.diff(dateOfBirth, "months", false) % 12;

      return {
        years: years,
        months: months,
      };
    },

    async toOrder() {
      this.isProcessing = true;

      await this.axios
        .post("order/product", this.mappingOrder, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (!response.data.error) {
            this.transactionId = response.data.response.transaction_id;
            this.paymentTransaction =
              response.data.response.payment_transaction;
            if (this.isFree) {
              let callback = process.env.VUE_APP_CALLBACK_DUITKU_MAILER

              if (this.sendTexter) {
                callback = process.env.VUE_APP_CALLBACK_MAILER_TEXTER
              }

              this.axios.post(callback, {
                merchantOrderId: this.transactionId,
                resultCode: "00"
              })
              this.trasactionStatus = {
                data: {
                  statusCode: "00"
                }
              }
              this.dialogStatus = true;
            }

            if (this.paymentTransaction) {
              this.showButtonCheck = true;
              this.paymentUrl =
                response.data.response.payment_transaction.pg_response.paymentUrl;
              this.openWindow(this.paymentUrl);
            }
          }
        })
        .catch((error) => {
          this.error = error.response.data.message;
        })
        .finally(() => (this.isProcessing = false));
    },

    openWindow(url) {
      const windowFeature =
        "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=700";
      window.open(url, "targetWindow", windowFeature);
    },

    async checkTransactionStatus() {
      await this.axios
        .post(
          "payment/transaction/check",
          {
            merchantCode: "DS14908",
            merchantOrderId: this.transactionId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.trasactionStatus = response;
          this.dialogStatus = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    successOrder() {
      if (
        this.trasactionStatus.data.statusCode === "00" ||
        this.trasactionStatus.data.statusCode === "02"
      ) {
        this.$router.go("/");
      }
    },

    buyProcess() {
      window.open(
        this.paymentTransaction.pg_response.paymentUrl,
        "_blank",
        "noreferrer"
      );
      setTimeout(() => {
        this.showButtonCheck = true;
      }, 2000);
    },

    checkVoucher() {
      const producttypeIds = this.cart[0].products[0].product_types.map(
        (item) => {
          return {
            producttype_id: parseInt(item.producttype_id),
            qty: item.qty
          }
        }
      );
      this.axios
        .post("voucher/check", {
          voucher_code: this.voucherCode,
          producttype_list: producttypeIds,
        })
        .then((response) => {
          this.showSnackbar("Berhasil memasang kupon/voucher");
          const result = response.data.response;
          this.responseVoucher = result;
          const amountDiscount = result.amount_discount

          this.cart.map((cart) => {
            cart.products.map((product) => {
              product.product_types.forEach((type) => {
                if (
                  type.producttype_id == this.responseVoucher.producttype_id
                ) {
                  this.discountVoucher = amountDiscount
                }
              });
            });
          });

          this.getPaymentMethod()
          this.selectPaymentMethod = null
        })
        .catch((error) => {
          this.showSnackbar("Gagal memasang kupon/voucher", true);
          console.log(error);
        });
    },

    refactorCart(cart) {
      return cart.map((cart) => {
        cart.products.map((product) => {
          product.product_types.map((type) => {
            type.product_id = parseInt(type.product_id);
            type.producttype_id = parseInt(type.producttype_id);
            return type;
          });
          return product;
        });
        return cart;
      });
    },

    showSnackbar(text, error = false) {
      this.snackbar.text = text;
      this.snackbar.show = true;
      this.snackbar.error = error;
    },
  },

  watch: {
    "dialog.status": {
      deep: true,
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.dialog.status = false;
          }, 3000);
        }
      },
    },
  },
};
</script>
