<template>
  <v-card v-if="
    ['5', '8', '9', '10', '11', '12', '13', '14', '15', '16'].includes(
      product?.product_category?.productcategory_id
    )
  " max-width="400" max-height="400" class="ma-4 pb-2" height="304" width="200"
    :disabled="product.quantity < 1 && product?.is_limited == true" :to="`/detail/${product.product_id}`">
    <v-img @contextmenu.prevent :src="product.product_details[0].productdetail_image_url"
      :lazy-src="product.product_details[0].productdetail_image_url" height="200" max-height="200" aspect-ratio="16/9"
      cover :class="{ bnw: is_upcoming }" class="align-start">
      <div v-if="product.quantity > 0 || product?.is_limited == false"
        class="bg-tertiary-dark rounded-be-lg d-inline-block">
        <p class="pa-2 text-bg-primary-dark text-body-2 font-weight-bold d-inline-block text-truncate"
          :style="{ 'max-width': '150px' }">
          {{ product.store_name }}
        </p>
      </div>

      <div v-else class="bg-red rounded-be-lg d-inline-block">
        <p class="pa-2 text-bg-white text-body-2 font-weight-bold">
          Habis Terjual
        </p>
      </div>
      <template v-slot:placeholder>
        <v-row align="center" class="fill-height ma-0" justify="center">
          <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-card-title class="text-body-2 font-weight-bold text-capitalize text-bg-background">
      {{ product.product_name }}
      <v-tooltip content-class="bg-grey" activator="parent" location="end center" origin="overlap">
        {{ product.product_name }}
      </v-tooltip>
    </v-card-title>

    <v-card-subtitle v-if="product?.discounted_price"
      class="text-neutral-50 font-weight-bold text-decoration-line-through">
      {{ numberToRupiah(product?.price_min) }}
    </v-card-subtitle>

    <v-card-subtitle class="text-secondary-60 font-weight-bold">
      {{ numberToRupiah(product?.discounted_price || product?.price_min) }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { utcToLocale } from "@/utils/dateFormatter";
import { numberToRupiah } from "@/utils/numberFormatter";

export default {
  name: "FunCard",

  props: ["product", "is_upcoming"],
  methods: {
    convertDate(isoDate) {
      return utcToLocale(isoDate, "DD-MM-YYYY");
    },

    numberToRupiah(number) {
      return numberToRupiah(number);
    },
  },
};
</script>